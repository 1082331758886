<template>
  <div class="auth">
    <div class="header">
      <img alt="Vue logo" src="./../../assets/logo.png" />
    </div>
    <div id="content">
      <slot />
    </div>
  </div>
</template>
<script>
import { useAuthStore } from '@/store/auth';

export default {
  name: "AuthLayout",
  mounted() {
    if(useAuthStore().state.status.loggedIn)
    {
      this.$router.push("/trips");
    }
  },
};
</script>
<style lang="sass" scoped>
.auth
  padding-top: 52px
  height: 100vh
  padding-left: 1rem
  padding-right: 1rem
  padding-bottom: 2rem
  width: 100%
  background: linear-gradient(to top left, #22b9cc, #25cf9e)

.header
  line-height: 50px
  height: 100px

  img
    margin: 0 auto
    width: 150px
    height: 52px
</style>
