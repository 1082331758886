<template>
  <Sidebar :trip="$route.params.id"></Sidebar>
  <Trip ref="trip" :trip_id="$route.params.id"></Trip>
  <div class="top"></div>
  <div class="content">
    <slot />
  </div>
</template>
<script>
export default {
  name: "TripLayout",
};
</script>
<style lang="sass">
.sidebar
  display: flex
  background-color: #FFFFFF
  height: 100vh
  width: 75px
  position: fixed

  @media (max-width: $media-mobile)
    position: absolute

.content
  display: flex
  height: 100vh
  padding-left: 4rem
  padding-right: 4rem
  flex-direction: column
  margin-left: 75px
  width: 100%

  @media (max-width: $media-mobile)
    overflow-x: none
    margin-left: 0
    padding-left: 2rem
    padding-right: 2rem
    margin-top: 2rem

  .header
    text-align: left
    width: auto
    padding-top: 3rem

    @media (max-width: $media-mobile)
      padding-top: 1rem

    .line
      margin-top: 3rem
      margin-bottom: 3rem
      border-bottom: 1px solid #ccc

.top
  height: 22px
  background-color: #888
  position: fixed
  z-index: 999
  left: 0
  right: 0
  display: none

  @media (max-width: $media-mobile)
    display: block

.content
  padding-top: 0

  @media (max-width: $media-mobile)
    padding-top: 22px
</style>
