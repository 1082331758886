import './css/lib/sscaffold.css'
import './css/lib/freakflags.css'

// import { Capacitor } from '@capacitor/core';

import { createApp } from 'vue'
import ActionCableVue from 'actioncable-vue';
import { createPinia } from 'pinia'

// App generic
import router from './router'
import store from "./store";
import App from './App.vue'

// Mixins
import stringMixin from '@/mixins/string.mixin'

// Components
import Link from './components/Link.vue'
import Header from './components/Header.vue'
import Sidebar from './components/Sidebar.vue'
import Trip from './components/Trip.vue'

// Custom components
import InlineSvg from 'vue-inline-svg';

const actionCableVueOptions = {
  debug: true,
  debugLevel: 'error',
  connectionUrl: process.env.VUE_APP_WEBSOCKET_URL,
  connectImmediately: false,
  store
};

window.vm = createApp(App)
  .use(createPinia())
  .use(store)
  .use(router)
  .use(ActionCableVue, actionCableVueOptions)
  .component('Trip', Trip)
  .component('Link', Link)
  .component('Header', Header)
  .component('Sidebar', Sidebar)
  .component('inline-svg', InlineSvg)
  .mount('#app')
