<template>
  <Sidebar></Sidebar>
  <div class="top"></div>
  <div class="content">
    <slot />
  </div>
</template>
<style lang="sass" scoped>
.top
  height: 22px
  background-color: #888
  position: fixed
  z-index: 999
  left: 0
  right: 0
  display: none

  @media (max-width: $media-mobile)
    display: block

.content
  margin-top: 0

  @media (max-width: $media-mobile)
    margin-top: 22px
</style>
