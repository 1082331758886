<script>
  import TripService from "@/services/trip.service";
  import { useAuthStore } from '../store/auth';
  import { useTripStore } from '../store/trip';

  export default {
    props: {
      trip_id: String,
    },
    name: "Trip",
    data() {
      return {
        tripStore: useTripStore(),
        authStore: useAuthStore()
      };
    },
    channels: {
      TripChannel: {
        connected() { console.log('Connected!') },
        rejected() {},
        received(data) {
          if(data.action == 'add_destinations'){
            this.$store.dispatch("trip/addDestination", data.destinations[0]);
          }
        },
        disconnected() {}
      }
    },
    methods: {
      sendMessage: function() {
        this.$cable.perform({
          channel: 'ChatChannel',
          action: 'send_message',
          data: {
            content: this.message
          }
        });
      }
    },
    mounted() {
      var self = this;

      // Load Trip data
      //if(!this.tripStore.current.trip?.id) {
        this.tripStore.showTrip(this.trip_id);
        this.tripStore.loadTripDestinations(this.trip_id);
      //}

      // Set up websocket connection
      this.$cable.connection.connect(process.env.VUE_APP_WEBSOCKET_URL + `?token=${this.authStore.state.user.accessToken}`);
      this.$cable.subscribe({
        channel: "TripChannel",
        id: this.trip_id
      });
    },
    render() {
      return ''
    }
  }
</script>
