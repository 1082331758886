import { defineStore } from 'pinia'

import TripService from '../services/trip.service';
import DestinationService from '../services/destination.service';


export const useTripStore = defineStore('trip', {
  state: () => ({
    categories: {},
    trips: [],
    destinations: [],
    current: { trip: {}, destinations: [] },
  }),
  actions: {
    /* getCategories - Load all categories */
    getCategories(){
      var self = this;
      TripService.getCategories().then(
        (response) => {
          self.categories = response.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    /* getTrips - Load all trips */
    getTrips(){
      var self = this;
      TripService.getTrips().then(
        (response) => {
          self.trips = response.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    /* showTrip - Load a specific trip */
    showTrip(trip_id){
      var self = this;
      TripService.getTrip(trip_id).then(
        (response) => {
          self.current.trip = response.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    loadTripDestinations(trip_id){
      var self = this;
      TripService.getDestinations(trip_id).then(
        (response) => {
          self.current.destinations = response.data;

          DestinationService.getDestinations(response.data.map(s=>s.destination_id)).then(
            (response2) => {
              self.destinations = self.destinations.concat(response2.data);
              self.current.destinations.map(function(destination){
                var destination_info = self.destinations.find(dest => dest.id == destination.destination_id);
                return Object.assign(destination, { info: destination_info });
              });
          });
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    loadDestination(destination_id) {
      var self = this;
      DestinationService.getDestinations([destination_id]).then(
        (response) => {
          self.destinations.push(response.data[0]);
          return response.data[0];
      });
    },
    addDestination(trip_id, destination) {
      var self = this;
      TripService.addDestination(trip_id, destination.id).then(
        (response) => {
          self.current.destinations.push(Object.assign(response.data, { info: destination }));
      });
    }
  }
});
// export const trip = {
//   namespaced: true,
//   state: {
//     current: {},
//     trip_destinations: [],
//     destinations: [],
//     categories: [],
//     users: [],
//     new_trip: {}
//   },
//   actions: {
//     list({ commit}) {
//       TripService.getTrips().then(
//         (response) => {
//           commit('listTrips', response.data);
//         },
//         (error) => {
//           this.content =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();
//         }
//       );
//     },
//     categories({ commit }) {
//       TripService.getCategories().then(
//         (response) => {
//           commit('loadCategories', response.data);
//         },
//         (error) => {
//           this.content =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();
//         }
//       );
//     },
//     load({ commit }, trip_id) {
//       TripService.getTrip(trip_id).then(
//         (response) => {
//           commit('loadCurrent', response.data);
//         },
//         (error) => {
//           this.content =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();
//         }
//       );
//       TripService.getDestinations(trip_id).then(
//         (response) => {
//           commit('loadTripDestinations', response.data);
//           DestinationService.getDestinations(response.data.map(s=>s.destination_id)).then(
//             (response2) => {
//               commit('loadDestinations', response2.data);
//           });
//         },
//         (error) => {
//           this.content =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();
//         }
//       );
//     },
//     loadDestination({ commit }, destination_id) {
//       DestinationService.getDestinations([destination_id]).then(
//         (response) => {
//           commit('addDestination', response.data[0]);
//       });
//     },
//     create({ commit }, trip_data) {
//       TripService.createTrip(trip_data).then(
//         (response) => {
//           commit('newTrip', response.data);
//           return response;
//         },
//         (error) => {
//           this.content =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();
//         }
//       );
//     },
//     addDestination({ commit }, destination) {
//       commit('addDestination', destination);
//     }
//   },
//   mutations: {
//     newTrip(state, trip) {
//       state.new_trip = trip;
//       state.current = trip;
//     },
//     loadCurrent(state, trip) {
//       state.current = trip;
//     },
//     loadTripDestinations(state, destinations) {
//       state.trip_destinations = destinations;
//     },
//     loadDestinations(state, destinations) {
//       state.destinations = state.destinations.concat(destinations);
//     },
//     listTrips(state, trips) {
//       state.trips = trips;
//     },
//     loadCategories(state, categories) {
//       state.categories = categories;
//     },
//     addDestination(state, destination) {
//       state.destinations.push(destination);
//     }
//   }
// };
