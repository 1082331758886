import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';

// Layouts
import AuthLayout from '@/views/layouts/AuthLayout.vue'
import TripLayout from '@/views/layouts/TripLayout.vue'
import DashboardLayout from '@/views/layouts/DashboardLayout.vue'
import EmptyLayout from '@/views/layouts/EmptyLayout.vue'

// Auth Store
import { useAuthStore } from '@/store/auth';

const routes = [
  {
    path: '/elements',
    name: 'Elements',
    component: () => import(/* webpackChunkName "auth" */ './views/Elements.vue'),
    meta: {
      layout: EmptyLayout,
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName "auth" */ './views/auth/Login.vue'),
    meta: {
      layout: AuthLayout,
      requiresAuth: false
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName "auth" */ './views/auth/Logout.vue'),
    meta: {
      layout: AuthLayout,
      requiresAuth: false
    }
  },
  {
    path: '/get-access',
    name: 'Signup',
    component: () => import(/* webpackChunkName "auth" */ './views/auth/Signup.vue'),
    meta: {
      layout: AuthLayout,
      requiresAuth: false
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName "dashboard" */ './views/Account.vue'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  },
  {
    path: '/friends',
    name: 'Friends',
    component: () => import(/* webpackChunkName "dashboard" */ './views/Friends.vue'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  },
  {
    path: '/trips',
    name: 'Trips',
    component: () => import(/* webpackChunkName "dashboard" */ './views/trips/Trips.vue'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  },
  {
    path: '/trips/add',
    name: 'AddTrip',
    component: () => import(/* webpackChunkName "dashboard" */ './views/trips/Add.vue'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  },
  {
    path: '/trips/:id',
    name: 'TripOverview',
    component: () => import(/* webpackChunkName "trips" */ './views/trips/trip/Overview.vue'),
    meta: {
      layout: TripLayout,
      requiresAuth: true
    }
  },
  {
    path: '/trips/:id/search',
    name: 'TripSearch',
    component: () => import(/* webpackChunkName "trips" */ './views/trips/trip/Search.vue'),
    meta: {
      layout: TripLayout,
      requiresAuth: true
    }
  },
  {
    path: '/trips/:id/friends',
    name: 'TripFriends',
    component: () => import(/* webpackChunkName "trips" */ './views/trips/trip/Friends.vue'),
    meta: {
      layout: TripLayout,
      requiresAuth: true
    }
  },
  {
    path: '/trips/:id/destinations/:destination_id',
    name: 'TripDestination',
    component: () => import(/* webpackChunkName "trips" */ './views/trips/trip/Destination.vue'),
    meta: {
      layout: TripLayout,
      requiresAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName "trips" */ './views/404.vue'),
    meta: {
      layout: EmptyLayout,
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    document.getElementById('app').scrollIntoView();
  },
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if(to.meta.requiresAuth && !authStore.state.status.loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
