<template>
  <div class="sidebar">
    <a href="#" class="logo" @click.prevent="this.$router.go(-1)">
      <inline-svg :src="require('../assets/logo-t.svg')"></inline-svg>
    </a>
    <div class="menu" v-if="trip">
      <Link :path="`/trips/${trip}`">
        <inline-svg :src="require('../assets/icons/home.svg')"></inline-svg>
        trip home
      </Link>
      <Link :path="`/trips/${trip}/search`">
        <inline-svg :src="require('../assets/icons/search.svg')"></inline-svg>
        search
      </Link>
      <Link :path="`/trips/${trip}/friends`">
        <inline-svg :src="require('../assets/icons/person.svg')"></inline-svg>
        friends
      </Link>
      <Link :path="`/trips`" classes="bottom">
        <inline-svg :src="require('../assets/icons/back.svg')"></inline-svg>
        back to
        <br/>
        trips
      </Link>
    </div>
    <div class="menu" v-else>
      <Link path="/trips">
        <inline-svg :src="require('../assets/icons/trips.svg')"></inline-svg>
        trips
      </Link>
      <Link path="/friends">
        <inline-svg :src="require('../assets/icons/person.svg')"></inline-svg>
        friends
      </Link>
      <Link path="/account">
        <inline-svg :src="require('../assets/icons/settings.svg')"></inline-svg>
        account
      </Link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    back: String,
    add: String,
    backpath: String,
    trip: String,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    toggleMenu() {
      var x = document.getElementById("profile_menu");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
  },
  mounted() {},
};
</script>
<style lang="sass" scoped>
.sidebar
  flex-direction: column

  .logo
    width: 100px
    height: 70px
    margin-top: 4rem

    svg
      margin-left: -25px
      width: 50px

  @media (max-width: $media-mobile)
    background-color: inherit
    width: 75px

    .logo
      background-color: white
      border-radius: 8rem
      width: 75px
      padding-left: 2.5rem
      height: 85px
      width: 90px
      margin-left: 2rem
      margin-top: 5.5rem
      padding-top: 0.5rem

      svg
        width: 75px


    .menu
      display: none

  .menu
    margin-top: 12rem

    a
      color: #959191
      display: flex
      flex-direction: column
      font-size: 1.2rem
      align-items: center
      margin-bottom: 4rem
      font-weight: 500

      svg
        width: 25px
        height: 25px
        fill: #959191
        margin-bottom: 0.5rem

      &:hover
        color: #41dbb9

        svg
          fill: #41dbb9

    .bottom
      padding-top: 3rem
</style>
