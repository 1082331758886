export const cable = {
  namespaced: true,
  state: { },
  actions: {
    connect({ commit }, trip_id) {


      // this.$cable.perform({
      //   channel: 'TripChannel',
      //   action: 'send_message',
      //   data: {
      //     content: this.message
      //   }
      // });

      // return AuthService.login(user).then(
      //   user => {
      //     commit('loginSuccess', user);
      //     return Promise.resolve(user);
      //   },
      //   error => {
      //     commit('loginFailure');
      //     return Promise.reject(error);
      //   }
      // );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
