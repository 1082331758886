import axios from 'axios';

const DESTINATION_URL = process.env.VUE_APP_DESTINATION_URL;

class DestinationService {
  getDestinations(ids) {
    return axios.get(DESTINATION_URL + 'destid', { params: { ids: ids.join(',') } });
  }
  isLiked(likes, user_id) {
    var found = likes.find(like => like == user_id)
    if(found) {
      return 'liked';
    }
    return false;
  }
}

export default new DestinationService();
