<template>
  <a :class="classes" :href="path" @click.prevent="$router.push({ path: path })">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    path: String,
    classes: String
  }
}
</script>
