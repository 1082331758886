<template>
	<component :is="this.$route.meta.layout || 'div'">
		<router-view />
	</component>
</template>

<script>
import { useTripStore } from './store/trip';

export default {
  name: 'App',
  mounted() {
    const tripStore = useTripStore();
    tripStore.getCategories();
  }
}
</script>

<style lang="sass">

html
	margin: 0
	padding: 0
	overflow: auto
	-webkit-overflow-scrolling: touch
	background-color: #F3FDFB

input:focus,
select:focus,
textarea:focus,
button:focus
	outline: none
	-webkit-border-radius: none

body
	margin: 0
	padding: 0
	height: 100vh
	-webkit-overflow-scrolling: touch

#app
  font-family: $default-fonts
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility
  text-align: center
  color: #6e6e6e
  height: 100vh
  display: flex
  flex-direction: row
</style>
