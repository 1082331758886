import axios from 'axios';
import authToken from './auth.token';

const API_URL = process.env.VUE_APP_API_BASE_URL;

class TripService {
  getTrips() {
    return axios.get(API_URL + 'trips.json', { params: { token: authToken() } });
  }
  getCategories() {
    return axios.get(API_URL + 'categories.json');
  }
  getDestinations(trip_id) {
    return axios.get(API_URL + `trips/${trip_id}/destinations.json`, { params: { token: authToken() } });
  }
  getTrip(trip_id){
    return axios.get(API_URL + `trips/${trip_id}.json`, { params: { token: authToken() } });
  }
  createTrip(trip) {
    return axios.post(API_URL + `trips.json`, { trip, token: authToken() });
  }
  addDestination(trip_id, destination_id) {
    return axios.post(API_URL + `trips/` + trip_id + `/destinations.json`, { destination_id: destination_id, token: authToken() });
  }
}

export default new TripService();
