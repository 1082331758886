export default {
  methods: {
    capitalize(string) {
      string && string.length > 0 && string[0].toUpperCase() + string.slice(1)
    },
    truncateText(text, length) {
      if (!text || text.length <= length) {
        return text;
      }

      return text.substr(0, length) + '\u2026'
    }
  }
}
