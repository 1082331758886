import { createStore } from "vuex";
//import { auth } from "./auth.module";
import { cable } from "./cable.module";
//import { trip } from "./trip.module";

const store = createStore({
  modules: {
    cable
  }
});

export default store;
