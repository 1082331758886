<template>
  <div class="header">
    <div class="title">
      <h1>{{ title }}</h1>
      <span class="subheader">{{ subtitle }}</span>
    </div>

    <div class="profile" @click.prevent="$event.target.classList.toggle('showlogout')">
      <div class="logout" style="display: none;">
        <a href="/logout">
          <div class="name">
            Log out
          </div>
          <div class="avatar">
            <inline-svg :src="require('../assets/icons/back.svg')"></inline-svg>
          </div>
        </a>
      </div>
      <div class="name">
        Nathalie
        <span>Berlin</span>
      </div>
      <div class="avatar">
        <img src="../assets/avatar.jpg" />
      </div>
    </div>
  </div>
  <div class="line"></div>
</template>
<script>
export default {
  props: {
    title: String,
    subtitle: String,
  },
};
</script>

<style lang="sass" scoped>
.header
  display: flex
  flex-direction: row
  justify-content: space-between

  @media (max-width: $media-mobile)
    flex-direction: column-reverse
    justify-content: space-around
    text-align: center

  .profile
    background-color: white
    display: flex
    align-items: center
    border-radius: 4rem
    padding: 1rem 1rem 1rem 2rem
    font-size: 1.7rem
    font-family: 'Jost'
    margin-bottom: 1.5rem

    .logout
      display: flex

      a
        display: flex

      svg
        fill: red

    .name
      font-weight: 500
      color: black
      letter-spacing: 1px

      span
        display: block
        font-weight: 400
        font-size: 1.5rem
        color: #999

    .avatar
      margin-left: 2rem

      img
        width: 75px
        height: 75px

    .menu
      position: absolute
      background-color: white
      top: 10rem

    @media (max-width: $media-mobile)
      padding: 1rem 1rem 1rem 1rem
      border-radius: 6rem
      width: 100px
      align-self: flex-end

      .name
        display: none

      .avatar
        margin-left: 0

.line
  border-bottom: 1px solid #d9d9d9

  @media (max-width: $media-mobile)
    margin-top: 2rem
</style>
